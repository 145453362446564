import { render, staticRenderFns } from "./SelecionaMetodoDePagamento.vue?vue&type=template&id=58371a44&scoped=true"
import script from "./SelecionaMetodoDePagamento.vue?vue&type=script&lang=js"
export * from "./SelecionaMetodoDePagamento.vue?vue&type=script&lang=js"
import style0 from "./SelecionaMetodoDePagamento.vue?vue&type=style&index=0&id=58371a44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58371a44",
  null
  
)

export default component.exports